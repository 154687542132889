import React, { Component } from 'react';
import { Route } from 'react-router';
require('bootstrap')

class Sidebar extends Component {
    constructor(props) {
        super();
        this.state = {
            balance: 0
        };
    }
    componentDidMount = () => {
        fetch('Front/GetBalance').then(x => x.json()).then(x => this.setState({ balance: x.balance }));
    }

    fetchOrder = () => {
        fetch('Api/PostContinueOrder', {
            method: "POST", body: '', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(x => x.text()).then(x => alert(x));
    }

    render() {
        return (
            <div className="sidebar">
                <div style={{ textAlign: 'center', marginTop: '15px', marginBottom: '10vh' }}>
                    <h4>CertificatSender</h4>
                </div>
                <p Style="text-align: center;border: 1px solid #dadada;padding: 8px;border-radius: 4px;margin: 16px;cursor: pointer;" onClick={() => this.fetchOrder()}>ContinueOrder</p>
              
                <ul className="list-group">
                    <li style={{ textAlign: 'center' }} className="list-group-item">Баланс:  <b>{this.state.balance}</b></li>

                    <li onClick={() => this.props.changeFrameLoad(0)} className="list-group-item btn btn-light">Статистика</li>
                    <li onClick={() => this.props.changeFrameLoad(4)} className="list-group-item btn btn-light">Резерв</li>
                    <li onClick={() => this.props.changeFrameLoad(1)} className="list-group-item btn btn-light">Ручне завантаження</li>
                    <li onClick={() => this.props.changeFrameLoad(8)} className="list-group-item btn btn-light">Черга</li>
                    <li onClick={() => this.props.changeFrameLoad(5)} className="list-group-item btn btn-light">Налаштування повідомлень</li>
                    <li onClick={() => this.props.changeFrameLoad(6)} className="list-group-item btn btn-light">Логи</li>
                    <li onClick={() => this.props.changeFrameLoad(7)} className="list-group-item btn btn-light">Інфо про сертифікати</li>
                    <li onClick={() => this.props.changeFrameLoad(10)} className="list-group-item btn btn-light">Фізичні сертифікати</li>
                    <li onClick={() => this.props.changeFrameLoad(11)} className="list-group-item btn btn-light">Європейські сертифікати</li>
                </ul>
            </div>
        );
    }

}
export default Sidebar;

/*
  <li onClick={() => this.props.changeFrameLoad(3)} className="list-group-item btn btn-light">Админка</li>
                    <li onClick={() => this.props.changeFrameLoad(2)} className="list-group-item btn btn-light">Создание проекта</li>
 */

